.MainMenuWrapper {
	display: inline-block;
	text-align: center;
	font-size: 14px;
	position: relative;
	z-index: 50;
}

.navigationBarWrapper .busy-wrapper {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 50px;
	padding: 12px 6px 6px 6px;
	box-sizing: border-box;
}



.MainMenuWrapper h2 {
	display: none;
}

.menuToggler {
	display: none;
	font-weight: 600;
	color: #ffcc00;
	cursor: pointer;
	padding: 6px;
}

.menuToggleIcon {
	vertical-align: middle;
	margin-left: 6px;
}

.menu_visible .menuToggleIcon_closed {
	display: none;
}
.menu_hidden .menuToggleIcon_open {
	display: none;
}

/* @media screen and (max-width: 770px) { */
@media screen and (max-width: 700px) {

	.navigationBarWrapper .busy-wrapper {
		height: 36px;
		padding: 3px 6px 6px 6px;
	}

	.MainMenuWrapper {
		width: 100%;
	}

	.menuToggler {
		display: block;
	}

	.MainMenuWrapper .menuWrapper {
		display: none;
	}

	.aside .menuWrapper {
		display: block;
	}

	.menu_visible .menuWrapper {
		display: block;
	}

	.menu_level_1 .subItemIcon {
		display: inline;
	}

	.MainMenuWrapper .menuItem_level_0 {
		border-bottom: 1px solid #111;
	}

	.subItemsIcon {
		display: none !important;
	}

	.menuItem.menuItem_level_1 {
		border-color: #333;
	}

	.menu_level_0 .menuItem_level_0 {
		/* border-top: 1px solid #666 !important; */
	}

	.menuItem {
		display: list-item;
		/* border: 1px solid #666; */
		border-top: 0;
	}
	.MainMenuWrapper .menu_level_0 .menuItem {
		margin: 0;
	}
	.MainMenuWrapper .menu_level_0 {
		border-top: 1px solid #666;
		border-bottom: 1px solid #666;
	}
	.MainMenuWrapper .menu_level_1 {
		position: relative;
	}
	.MainMenuWrapper .menu_level_1 .menuItem {
		padding-left: 12px;
	}
}