.paginationWrapper {
	position: relative;
	display: block;
	box-sizing: border-box;
	text-align: center;
	color: #fff;
	width: 100%;
	padding: 10px 0;
	/* margin-top: 12px;
	border: 1px solid #000;
	border-width: 1px 0; */
}

.pagination {
	display: block;
	position: relative;
	list-style: none;
	padding: 0;
}

.paginationItem {
	display: inline-block;
	padding: 0.5em;
	vertical-align: middle;
	font-weight: 600;
	font-weight: 100;
	transition: background-color linear 0.12s;
	/* font-size: 13px; */
}

.paginationItem {
	cursor: pointer;
	color: #ffcc00;
	vertical-align: middle;
	transition: color linear 0.12s;
}

.paginationItem:hover {
	background-color: rgb(75, 75, 75);
}

.paginationItem:hover .paginationText {
	color: #fff;
}

.paginationItem_current .paginationText {
	color: #fff;
	font-weight: 600;
}

.paginationEllipsis_after::after {
	content: "…";
	color: #fff;
	margin-left: 12px;
}
.paginationEllipsis_before::before {
	content: "…";
	color: #fff;
	margin-right: 12px;
}

.postPaginationItem {
	/* padding: 0;*/
	margin: 0; 
	box-sizing: border-box;
	position: relative;
	width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.postPaginationItem_0 {
	/* padding-left: 0; */
	text-align: left;
}
.postPaginationItem_1 {
	/* padding-right: 0; */
	text-align: right;
}