.PostContent {
	font-size: 15px;
	line-height: 1.5em;
	/* white-space: nowrap; */
	overflow: hidden;
	text-overflow: ellipsis;


	
}
@media screen and (min-width: 1200px) {
	.PostContent {

		max-width: 768px;
		margin: 0 auto;
		padding-left: 24px;
		border-left: 1px solid black;
		/* padding-right: 48px;
		border-right: 1px solid black; */
		margin-bottom: 48px;
	}
}
.wp-embed-aspect-16-9 {
	padding-bottom: 56.25%; /* 9:16=0.5625 */
}

.wp-embed-aspect-16-9 iframe,
.wp-embed-aspect-16-9 embed,
.wp-embed-aspect-16-9 object
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.wp-embed-aspect-4-3 {
	padding-bottom: 75%; /* 3:4=0.75 */
}

.wp-embed-aspect-4-3 iframe,
.wp-embed-aspect-4-3 embed,
.wp-embed-aspect-4-3 object
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

img.alignleft {
	float: left;
	margin: 0 12px 6px 0;
}

.PostContent img {
	max-width: 100% !important;
	height: initial;
}

.PostContent h2 {
	margin: 12px auto;
	font-size: 36px;
    line-height: 1.1em;
	font-family: 'bebas-neue', Impact, sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    margin-top: 6px;
    color: #fff; 
}

.PostContent h3 {
	font-weight: 600;
	font-size: 24px;
    line-height: 1.1em;
	margin: 24px 0 12px 0;
}
.PostContent h4 {
	font-weight: 600;
	font-size: 18px;
    line-height: 1.1em;
	margin: 24px 0 12px 0;
}