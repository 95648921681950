* {
	-webkit-touch-callout: none;
	/*-webkit-tap-highlight-color: rgba(255,255,255,0);*/
	-webkit-text-size-adjust: none;
	/*-webkit-user-select: none;*/
}

body, html {
	padding: 0;
	margin: 0;
	height: 100%;
	/* font-family: "Source Sans Pro", Helvetica, Arial, sans-serif; */
	font-family: "libre-franklin","Source Sans Pro","Helvetica Neue", Helvetica, sans-serif,Arial,sans-serif;
	/* font-family: "freight-sans-pro","Source Sans Pro","Helvetica Neue", Helvetica, sans-serif,Arial,sans-serif; */
	font-size: 13px;
	line-height: 1.3em;
	font-weight: normal;
}

body {
	position: relative;
	background-color: #333;
	color: #fff;
}

.App {
	position: relative;
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
  
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
  
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
  
	/* Support for IE. */
	font-feature-settings: 'liga';
	vertical-align: middle;
}

.wp-block-embed {
	text-align: center;
}

p {
	margin: 0;
	margin-bottom: 0.75em;
}

b {
	font-weight: 600;
}

figure {
	padding: 0;
	margin: 1em 0;
	text-align: center;
	position: relative;
	display: block;
	width: 100%;
	max-width: 100% !important;
}

figure img {
	position: relative;
	max-width: 100% !important;
	height: initial;
}

table {
	width: 100%;
	/* max-width: 568px; */
	border-collapse: collapse;
	margin: 12px 0;
}

hr {
	width: 100px;
	border: 0;
	border-bottom: 1px solid rgb(51, 51, 51);
	margin-top: 12px;
	margin-bottom: 12px;
}

td {
	padding: 3px 0;
	border-bottom: 1px solid rgb(51, 51, 51);
	text-align: left;
}

.has-text-align-right {
	text-align: right;
}

.has-text-align-center {
	text-align: center;
}

.clear {
	position: relative;
	display: block;
	float: none;
	clear: both;
	height: 0px;
	width: 0px;
	line-height: 0;
}

.clearfix {
 	content: "";
	clear: both;
	display: block;
	visibility: hidden;
	height: 0px;
}

a, a:active, a:visited {
	text-decoration: none;
	color: #ffcc00;
}

a:hover {
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 1.3em;
	line-height: 1.1em;
	margin: 0;
	font-weight: bold;
}

.header h1 {
	width: 100%;
	text-align: center;
}

.underConstruction {
	text-align: center;
	background-color: #ffcc00;
	text-shadow: none;
	color: black;
	margin-top: 12px;
	text-transform: uppercase;
	padding: 8px 0 6px 0;
	font-weight: 600;
	opacity: 0.75;
}

ul, li {
	margin: 0;
	padding: 0;
	list-style: none;
}

.heroHeader {
	position: relative;
	height: auto;	
	overflow: hidden;
	max-height: 100vh;
	/* display: none; */
	/* transition: max-height linear 0.2s; */
}

.heroPic {
	position: relative;
	display: block;
	width: 100%;
}

.mainTitle {
	cursor: pointer;
	position: absolute;
	bottom: 0;
	text-shadow: 0 0 24px #000, 0 0 12px #000, 0 0 6px #000;
	width: 100%;
	text-align: center;
	padding-bottom: 12px;
}

.mainTitle h1 {
	font-family: 'bebas-neue', Impact, "Source Sans Pro","Helvetica Neue", Helvetica, sans-serif,Arial,sans-serif;
	text-transform: uppercase;
	font-style: normal;
	font-weight: normal;
	font-size: 54px;
	text-align: left;
	margin-top: 6px;
	color: #fff;
	text-align: center;
}

.claim {
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	text-transform: uppercase;
	/* letter-spacing: 0.5px; */
}

.usr {
	margin-bottom: 12px;
}

.header {
	position: relative;
	z-index: 10;
	background-color: rgba(51,51,51,0.95);
	width: 100%;
	display: block;
	top: 0;
	padding: 0;
	/* position: fixed; */
}

.navigationBar {
	position: relative;
	display: block;
	text-align: center;
}

.rnwHeroLogo {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-top: -0.25em;
	height: 2em;
	width: 2em;
	cursor: pointer;
	background: url(./rnw-hero-logo.png) no-repeat center;
	background-size: contain;
}

.layout_post .heroHeader,.layout_page .heroHeader {
	display: none;
	/* max-height: 0; */
}

.compacted .heroHeader {
	/* display: none;*/
	/* max-height: 0;  */
}

.layout_home .inanimate .heroHeader {
	transition: none;
}

.navigationBarWrapper {
	position: relative;
	top: 0;
	width: 100%;
	display: block;
	background-color: #333;
	/* padding-bottom: 6px; */
}

.compacted .navigationBarWrapper, .layout_post .navigationBarWrapper, .layout_page .navigationBarWrapper {
	position: fixed;
	top: 0;
}

/* .sticky + .main {
	padding-top: 260px;
} */


.main {
	position: relative;
	display: block;
	box-sizing: border-box;
	/* max-width: 768px;
	max-width: 1024px; */
	margin: 0 auto;
	text-align: left;
}

.main .busy-wrapper {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	padding: 48px 12px;
}

.App.layout_home:not(.header_compacted) .main {
	padding-top: 0 !important;
}

.main::after {
	content: "";
	clear: both;
	display: table;
}

.main, .aside {
	box-sizing: border-box;
}

h2 {
	margin: 11px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 15px;
	line-height: 1.2em;
}

.content {
	box-sizing: border-box;
	position: relative;
	width: 75%;
	min-width: 75%;
	float: left;
	min-height: 33vh;
}

.aside {
	width: 25%;
	float: right;
}


.footer {
	display: block;
	position: relative;
	box-sizing: border-box;
	padding: 12px;
	padding: 6px;
	text-align: center;
	color: #fff;
	border-top: 1px solid #444;
	background-color: #000;
}

/* 
div, nav, section, h1 {
	border: 1px solid burlywood !important;
}
div::after, nav::after {
	clear: both;
	display: table;
}

.main, .header, .PostListing {
	overflow: auto;
} */

.searchWrapper {
	padding: 12px 24px;
	padding-right: 12px;
}

.searchForm {
	/* margin: 12px; */
	position: relative;
	vertical-align: middle;
	background-color: #000;
}

.searchForm button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;	
	border-radius: 0;
	border: none;
	color: #000;
}

.searchForm button::after {
	clear: both;
	display: table;
}

input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;	
	border-radius: 0;
	outline: none;
	font-family: inherit;
	font-size: 16px;
}

input:focus-visible {
	-webkit-appearance: none;
	font-family: inherit;
    outline: none;
}

.searchForm input {
	vertical-align: middle;
	width: 80%;
	display: inline-block;
	padding: 6px;
	border: none;
	background-color: #000;
	color: #fff;
}

.searchForm button {
	vertical-align: middle;
	width: 20%;
	box-sizing: border-box;
	display: inline-block;
	padding: 6px;
	font-family: inherit;
	font-size: inherit;
}

.spotifyPlayerWrapper {
	display: block;
	margin-top: 24px;
	margin-bottom: 24px;
	border-top: 6px solid #444;
	border-bottom: 6px solid #444;
	background-color: #000;
}

.spotifyPlayer {
	width: 100%;
	height: 360px;
	margin: 0;
	padding: 0;
}

@media screen and (max-width: 700px) {

	.navigationBar {
		padding-top: 6px;
		padding-bottom: 3px;
	}

	.rnwHeroLogo {
		position: absolute;
		height: 2em;
		left: 0.5em;
		z-index: 100;
		margin-top: 2px;
	}

	.content {
		width: 100%;
	}

	.aside {
		width: 100%;
	}

}