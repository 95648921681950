.postHeaderTitle {
	font-family: 'bebas-neue', Impact, sans-serif;
	text-transform: uppercase;
	font-style: normal;
	font-weight: normal;
	font-size: 48px;
	text-align: left;
	margin-top: 6px;
	color: #ffcc00;
}

.postView_list .PostHeader h1 {
	font-size: 36px;
	line-height: 1.1em;
}

.postDate {
	font-size: 12px;
}

.postCategories {
	display: inline;
	margin: 0;
	padding: 0;
	list-style-image: none;
	margin-bottom: 24px;
}

.postCategories::after {
	content: "";
	clear: both;
	display: block;
	visibility: hidden;
	height: 12px;
}

.postCategories li {
	display: inline-block;
	font-size: 12px;
	line-height: 1em;
	padding-right: 6px;
	margin-right: 6px;
	border-right: 1px solid #fff;
}

.postCategories li:last-child {
	border: none;
}

.PostHeader h1 {
	cursor: pointer;
}

.PostHeader h1:hover {
	text-decoration: underline;
}

.postView_full .PostHeader h1 {
	cursor: default;
}
.postView_full .PostHeader h1:hover {
	text-decoration: none;
}

.categoryLink {
	color: #ffcc00;
	cursor: pointer;
}

.categoryLink:hover {
	text-decoration: underline;
}

.categoriesTitle {
	font-size: 13px;
	display: block;
	display: inline-block;
	margin-right: 6px;
}