.Breadcrumbs {
	font-size: 12px;
	text-align: center;
	margin-top: 0px;
	padding: 6px;
	/* border-top: 1px solid black;
	border-bottom: 1px solid black; */
	background-color: #444;
	background-color: #000;
}

.breadcrumbsWrapper {
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.breadcrumbItem {
	list-style: none;
	display: inline-block;
	cursor: pointer;
	color: #ffcc00;
	vertical-align: middle;
	line-height: 1;
	/* line-height: 0;
	height: 2em; */
}

/* #breadcrumbItem_search {
	color: #fff;
}
#breadcrumbItem_search:hover {
	text-decoration: none;
} */

.breadcrumbItem:hover {
	text-decoration: underline;
}

.breadcrumbText {
	line-height: 1;
	vertical-align: middle;
}

.breadcrumbHome {
	color: #000;
	width: 0;
	overflow: hidden;
}

.breadcrumbItem_post {
	text-decoration: none;
	cursor: default;
	color: #fff;
}
.breadcrumbItem_post:hover {
	text-decoration: none;
}

.breadcrumbSlash {
	display: inline-block;
	margin: 0 0px;
	/* vertical-align: middle; */
	color: #fff;
}

.breadcrumbItem_tag {
	font-style: italic;
}