
.postTags {
	display: inline;
	margin: 0;
	padding: 0;
	list-style-image: none;
	margin-bottom: 24px;
}

.postTags::after {
	content: "";
	clear: both;
	display: block;
	visibility: hidden;
	height: 24px;
}

.postTags li {
	display: inline-block;
	font-size: 12px;
	line-height: 1em;
	padding-right: 6px;
	margin-right: 6px;
	border-right: 1px solid #fff;
}

.postTags li:last-child {
	border: none;
}
.tagLink {
	color: #ffcc00;
	cursor: pointer;
}

.tagLink:hover {
	text-decoration: underline;
}

.tagsTitle {
	font-size: 13px;
	display: block;
	display: inline-block;
	margin-right: 6px;
}