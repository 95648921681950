.Post {
	position: relative;
	box-sizing: border-box;
	background-color: #222;
	/* border: 1px solid white; */
	/* border-bottom: 1px solid #ffcc00; */
	margin-bottom: 12px;
	padding-bottom: 12px;
	padding: 12px;

}

.Post.postView_list {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	border-right: 12px solid #333;
}

@media screen and (max-width: 1200px) {
	.Post.postView_list {
		flex-basis: 50%;
	}
	.Post.postView_list:nth-child(2n+2) {
		border: none;
	}
}



@media screen and (min-width: 1200px) {
	.Post.postView_list {
		flex-basis: 33.33%;
	}
	.Post.postView_list:nth-child(3n+3) {
		border: none;
	}
}
@media screen and (max-width: 800px) {
	.Post.postView_list {
		flex-basis: 100%;
	}
	.Post.postView_list {
		border: none;
	}
}

.postView_list .categories-wrapper {
	display: none;
}

.postView_list .PostFooter {
	display: none;
}

.postView_full {
	margin-bottom: 0;
}