.Busy {
	position: relative;
	width: 100%;
	text-align: center;
	height: 100%;
}

.loading {
	position: relative;
	font-size: 14px;
	/* height: 50vh;
	min-height: 2em; */
}
.spinnerRing {
	display: inline-block;
	position: relative;
	width: 42px;
	height: 42px;
	margin: 0 12px 0 0;
	top: 33%;
}
.spinnerRing:after {
	clear: both;
	display: block;
	visibility: hidden;
	height: 0px;
}
.spinnerRing div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 42px;
	height: 42px;
	margin: 0px;
	border: 4px solid #000;
	border-radius: 50%;
	animation: spinnerRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #999 transparent transparent transparent;
}

.Busy.small .spinnerRing {
	width: 12px;
	height: 12px;
	margin: 0 3px 0 0;
	top: 0;
}

.Busy.small .spinnerRing div {
	width: 12px;
	height: 12px;
	margin: 0px;
	border: 2px solid #000;
	margin: 6px 0;
	border-radius: 50%;
	animation: spinnerRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #999 transparent transparent transparent;
}

.spinnerRing div:nth-child(1) {
	animation-delay: -0.45s;
}
.spinnerRing div:nth-child(2) {
	animation-delay: -0.3s;
}
.spinnerRing div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes spinnerRing {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
