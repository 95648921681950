.Menu {
	box-sizing: border-box;
}

.menuWrapper {
	display: inline-block;
	vertical-align: middle;
}

.menuItem {
	padding: 0 0;
	margin: 0;
	list-style: none;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	background-color: #333;
	color: #ffcc00;
	cursor: pointer;
	transition: background-color linear 0.12s, color linear 0.12s;
	font-weight: 600;
}

.Menu_main .menuItem_level_0 {
	border-right: 1px solid #444;
}

.menuItemText {
	display: block;
	padding: 6px 10px;
}

.menuItem_level_0 .menuItemText {
	display: inline-block;
	/* padding: 6px;
	margin: 3px 3px;	 */
	text-align: left;
}

.menuItem:hover {
	background-color: rgb(75, 75, 75);
	color: #fff;
}

.menuItem_level_0 {
	padding: 12px 6px 10px 6px;
}

.subItemsIcon {
	display: none;
	margin-left: -6px;
}

.subItemIcon {
	vertical-align: baseline;
	display: none;
	font-size: inherit;
}

.hasSubItems > .subItemsIcon_open {
	display: inline;
}
.hasSubItems:hover > .subItemsIcon_open {
	display: none;
}
.hasSubItems:hover > .subItemsIcon_close {
	display: inline;
}

.menuItem_level_1 {
	border: 1px solid #444;
	border-top: none;
	display: list-item;
	font-weight: 400;
}

.menuItem_level_1:first-child {
	/* border-top: 1px solid #444; */
}

.menu_level_1 {
	position: absolute;
	display: none;
	/* box-shadow: 0 0 5px black; */
}

.menuItem_level_0:hover .menu_level_1 {
	display: block;
}

.aside .Menu {
	/* border: 2px solid green;
	width: 200px; */
	/* font-size: 14px;
	line-height: 1.2em; */
	margin-bottom: 24px;
	padding: 12px;

}

.aside .Menu .menuItem {
	display: inline-block;
	font-weight: 100;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #444;
	width: 100%;
}

@media screen and (max-width: 700px) {

	.Menu_main {
		position: absolute;
		width: 100%;
	}

	/* .aside .Menu {
		width: 50%;
		float: left;
	} */
}

@media screen and (max-width: 400px) {
	.aside .Menu {
		width: 100%;
	}
}