
.PostListing {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
  	width: 100%;
	position: relative;
	box-sizing: border-box;
}

