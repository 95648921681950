.Contacts {
	padding: 12px;
	/* padding: 0; */
	position: relative;
	margin: 0;
}

.Contacts h2 {
	/* margin: 11px; */
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.2em;
}

.contactsWrapper {
	position: relative;
	box-sizing: border-box;
	padding: 0 11px;
	width: 100%;
}

.contactItem {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	line-height: 0;
	background-color: #000;
	margin: 6px;
	margin-left: 0;
	margin-top: 0;
	cursor: pointer;
}

.contactItem a {
	display: inline-block;
	padding: 6px;
	color: #fff;
	box-sizing: border-box;
	text-align: center;
}

.contactSymbol {
	display: block;
	width: 2.2em;
	position: relative;
	background-color: #fff;
	transition: background-color linear 0.2s;
}
.contactItem:hover .contactSymbol {
	background-color: #ffcc00;
}