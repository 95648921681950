.FeaturedMedia {
	position: relative;
	display: block;
	border: 1px solid #666;
	overflow: hidden;
	margin-bottom: 6px;
	transition: opacity linear, border-color linear 0.25s;
	cursor: pointer;
}

.postView_full .FeaturedMedia {
	margin-bottom: 24px;
}

.FeaturedMedia:hover {
	border-color: #ffcc00;
}

.postView_full .FeaturedMedia {
	cursor: default;
}
.postView_full .FeaturedMedia:hover {
	border-color: #666;
}

.featured-container_16_9 {
	padding-bottom: 56.25%; /* 9:16=0.5625 */
}
.featured-container_4_3 {
	padding-bottom: 75%; /* 3:4=0.75 */
}

.featured-container_no_media {
	height: 0;
	padding: 0;
	display: none;
}

.featuredImagePic {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: opacity linear 0.15s;
}

.featuredImagePic.loading {
	opacity: 0.1;
}

.iamerror {
	text-transform: uppercase;
	background-color: #000;
	color: #fff;
	text-align: center;
	padding: 2em;
}

.onTop {
	position: relative;
	z-index: 1000;
}